<template>
  <div :data-children="childAmount" invitation-event-bottom-detail>
    <div class="coach-invite-bottom-detail">
      <article>
        <h1>{{ $t('coaching.MT_TERMS_RECEIVE_REFERRAL') }}</h1>
        <ul>
          <li v-for="(str, i) in strListByKey('coaching.MC_TERMS_SEND_REFERRAL_A')" :key="i + str">
            {{ str }}
          </li>
        </ul>
      </article>
      <article>
        <h2>{{ $t('coaching.MC_TERMS_SEND_REFERRAL_B_TITLE') }}</h2>
        <ul>
          <li v-for="(str, i) in strListByKey('coaching.MC_TERMS_SEND_REFERRAL_B_BODY')" :key="i + str">
            {{ str }}
          </li>
        </ul>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvitationEventBottomDetail',
  computed: {
    childAmount() {
      const children = this.$slots?.default || [];
      return children.filter(c => (c || {}).tag).length;
    },
  },
  methods: {
    strListByKey(key) {
      const str = this.$t(key);
      if (!str) return [];
      return str.split('{/}');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[invitation-event-bottom-detail] { .w(100%); .bgc(#f3f3f9);
  .coach-invite-bottom-detail {
    max-width: 1260px;
    margin: auto;
    display: flex;
    @media all and (min-width: 1260px) {
      flex-direction: row;
      padding-top: 100px;
      padding-bottom: 100px;
      padding-left: 58px;
      padding-right: 58px;
      justify-content: space-between;
    }
    @media (min-width: 376px) and (max-width: 1259px) {
      padding-left: 58px;
      padding-right: 58px;
      padding-top: 100px;
      padding-bottom: 100px;
      flex-direction: column;
    }
    @media all and (min-width: 0px) and (max-width: 767px) {
      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 20px;
      padding-right: 20px;
      flex-direction: column;
    }
  }
  .coach-invite-bottom-detail {
    li {
      color: #575c79;
      position: relative;
      @media all and (min-width: 376px) {
        font-size: 16px;
        margin-left: 20px;
        line-height: 28px;
        &::before {
          left: -15px;
          top: 12px;
          width: 5px;
          height: 5px;
        }
      }
      @media all and (min-width: 0px) and (max-width: 767px) {
        font-size: 14px;
        margin-left: 15px;
        line-height: 24px;
        &::before {
          left: -12px;
          top: 11px;
          width: 3px;
          height: 3px;
        }
      }
      &::before {
        position: absolute;
        content: '';
        border-radius: 100%;
        background-color: #575c79;
      }
    }
    h1 {
      font-family: KotraBold;
      color: #1f2226;
      @media all and (min-width: 376px) {
        height: 35px;
        font-size: 24px;
        margin-bottom: 24px;
      }
      @media all and (min-width: 0px) and (max-width: 767px) {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
    h2 {
      font-family: KotraBold;
      @media all and (min-width: 376px) {
        font-size: 18px;
        margin-bottom: 12px;
      }
      @media all and (min-width: 0px) and (max-width: 767px) {
        font-size: 14px;
        margin-bottom: 8px;
      }
      font-weight: 700;
      color: #2a3051;
    }
    article,
    div {
      @media all and (min-width: 376px) {
        display: flex;
        flex-direction: column;
      }
      @media (min-width: 376px) and (max-width: 1259px) {
        margin-bottom: 20px;
      }
      @media all and (min-width: 0px) and (max-width: 767px) {
        margin-bottom: 40px;
      }
      @media all and (min-width: 1260px) {
        &:not(:first-child) {
          margin-top: calc(35px + 24px);
        }
      }
    }
  }
  .coach-invite-bottom-detail[data-children='3'] {
    @media all and (min-width: 1260px) {
      article,
      div {
        flex-basis: 30%;
      }
    }
  }
  .coach-invite-bottom-detail[data-children='2'] {
    @media all and (min-width: 1260px) {
      article,
      div {
        flex-basis: 45%;
      }
    }
  }
}
</style>
