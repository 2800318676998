<template>
  <div invitation-event-middle>
    <div class="inner">
      <div class="title-wrap">
        <h2>
          내 친구가 첫 수업 들으면<br><em>이만큼 드릴게요!</em>
        </h2>
        <img src="/img/icon/illust-coupon-blue.svg">
      </div>
      <TableBenefits>
        <TableBenefitsRow>
          <template #title><h4>{{ $t('coaching.MC_TITLE_SUMMARY_A_SEND_REFERRAL') }}</h4></template>
          <template #desc>
            <p>
              친구 초대 보상금 1,000UP<br>
              <span>
                (최대 누적 가능 혜택 100만원 상당)
              </span>
            </p>
          </template>
        </TableBenefitsRow>
        <TableBenefitsRow>
          <template #title>{{ $t('coaching.MC_TITLE_SUMMARY_B_SEND_REFERRAL') }}</template>
          <template #desc><em>{{ $t('coaching.MC_DESCRIPTION_SUMMARY_B_SEND_REFERRAL') }}</em></template>
        </TableBenefitsRow>
        <TableBenefitsRow>
          <template #title>{{ $t('coaching.MC_TITLE_SUMMARY_C_SEND_REFERRAL') }}</template>
          <template #desc><p>{{ $t('coaching.MC_DESCRIPTION_SUMMARY_C_SEND_REFERRAL') }}</p></template>
        </TableBenefitsRow>
        <TableBenefitsRow>
          <template #title>{{ $t('coaching.MC_TITLE_SUMMARY_D_SEND_REFERRAL') }}</template>
          <template #desc><p>{{ $t('coaching.MC_DESCRIPTION_SUMMARY_D_SEND_REFERRAL') }}</p></template>
        </TableBenefitsRow>
      </TableBenefits>
    </div>
  </div>
</template>

<script>
import '@/utils/polyfillUtils';
import TableBenefits from '@/views/components/coaching/event/TableBenefits.vue';
import TableBenefitsRow from '@/views/components/coaching/event/TableBenefitsRow.vue';

export default {
  name: 'InvitationEventMiddle',
  components: { TableBenefitsRow, TableBenefits },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[invitation-event-middle] { .w(100%); .bgc(#2f50f8);
  .inner { .flex; .justify-center; flex-direction: column; .m(auto); .max-w(370); .p(40, 20); }
  .title-wrap { text-align: center; .mb(16);
    > h2 {.fs(24); .lh(34); font-family: KotraBold; text-align: center; .c(#fff); .mb(16);
      > em {.c(#fcd1ff);}
    }
    > img {.w(198); .h(98); }
  }

  @media (@tp-up) {
    .inner {.max-w(594); .p(100, 0);}
    .title-wrap { .mb(60);
      > h2 {.fs(36); .lh(50); }
      > img {.w(245); .h(120); }
    }
  }
}

</style>
