<template>
  <Modal invitation-event-modal @close="onClose">
    <template #title>
      <h1 class="title">{{ $t('coaching.PC_LABEL_INPUT_INVITATION') }}</h1>
    </template>
    <template>
      <div class="content-wrap">
        <div class="invitation-code-wrap">
          <span class="invitation-code">{{ invitationCode }}</span>
        </div>
        <h2 class="share-text">{{ $t('coaching.PC_LABEL_SHARE_INVITATION') }}</h2>
        <div class="share-wrap">
          <button class="kakao" @click="_shareKakao">
            <img src="/img/icon/kakao-share.svg">
            <label>{{ $t('coaching.BT_LABEL_SHARE_KAKAOTALK') }}</label>
          </button>
          <button class="link-copy" @click="shareLink">
            <img src="/img/icon/link-copy.svg">
            <label>{{ $t('coaching.BT_LABEL_SHARE_COPYLINK') }}</label>
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import share from '@shared/mixins/share';
import { copyClipboard } from '@shared/utils/commonUtils';

export default {
  name: 'InvitationEventModal',
  components: { Modal },
  mixins: [share],
  props: {
    options: {},
  },
  computed: {
    invitationCode() {
      return this?.options?.code;
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async _shareKakao() {
      const host = process.env.VUE_APP_HOME_URL;
      const path = this.$router.resolve({ name: 'CoachEventInvitationReceive', query: { 'invitation-code': this.invitationCode } })?.href;
      const url = `${host}${path}`;
      await this.shareKakao({ url, title: '레벨업코치 친구 초대 이벤트', description: '친구로부터 무료 수강권 도착했어요', imageUrl: 'https://d3hqehqh94ickx.cloudfront.net/prod/images/user/60232f5846e0fb00012087ad_1625472020897_1625472020996.png' });
      this.$emit('resolve');
    },
    shareLink() {
      const host = process.env.VUE_APP_HOME_URL;
      const path = this.$router.resolve({ name: 'CoachEventInvitationReceive', query: { 'invitation-code': this.invitationCode } })?.href;
      const url = `${host}${path}`;
      copyClipboard(url);
      this.$toast('coaching.COACH_URL_COPIED_TO_CLIPBOARD');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[invitation-event-modal] {
  .title {.fs(20); .c(#191919);}
  .content-wrap { .mt(16); .mb(40);
    .invitation-code-wrap { .flex; border: solid 1px #d5d5de; .h(60); .pl(20); .mb(32); .items-center;
      .invitation-code {.c(#2f50f8); .fs(18); font-style: normal; font-weight: normal;}
    }
    .share-text {.fs(20); .c(#191919);}
    .share-wrap { .flex; .mt(24); column-gap: 40px; .justify-center;
      > button {.flex; flex-direction: column;
        > label {.mt(12); .fs(14); .c(#787781);}
      }
      .kakao {}
      .link-copy {}
    }
  }
}
</style>
