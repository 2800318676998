<template>
  <div invitation-event-info-panel>
    <div class="inner">
      <div class="title-wrap">
        <h2>
          초대 링크와 함께 <em>선물하세요!</em>
        </h2>
        <p>{{ $t('coaching.MC_SUBTITLE_SEND_REFERRAL') }}</p>
      </div>
      <div class="panel-wrap">
        <div v-show="showCover" class="cover">
          <img src="/img/icon/icon-face-emoji.svg">
          <p>{{ $t('coaching.MC_NOT_FOUND_SEND_PROGRESS') }}</p>
        </div>
        <div class="progress invitation-amount">
          <div class="progress-top">
            <img src="/img/icon/icon-firecracker.svg">
            <h4>{{ $t('coaching.PC_TITLE_SUCCESS_SEND') }}</h4>
            <span>
              <span><em>{{ _inviteeCount }}</em></span>
              <span>/</span>
              <span>{{ _maxInviteeCount }}명</span>
            </span>
          </div>
          <div class="progress-bottom">
            <div class="progress-bar-holder">
              <div :style="{width: `${inviteeProgressWidth}%`}" class="progress-bar" />
            </div>
          </div>
        </div>
        <div class="progress reward-amount">
          <div class="progress-top">
            <img src="/img/icon/icon-fly-money.svg">
            <h4>{{ $t('coaching.PC_TITLE_SEND_REWARD') }}</h4>
            <span>
              <span><em>{{ _rewardAmount }}</em></span>
              <span>/</span>
              <span>{{ _maxRewardAmount }}UP</span>
            </span>
          </div>
          <div class="progress-bottom">
            <div class="progress-bar-holder">
              <div :style="{width: `${rewardProgressWidth}%`}" class="progress-bar" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { commaDecimal } from '@shared/utils/numberUtils';

export default {
  name: 'InvitationEventInfoPanel',
  props: {
    inviteeCount: { type: Number, default: 50 },
    reward: { type: Number, default: 50000 },
    showCover: { type: Boolean, default: true },
  },
  computed: {
    _inviteeCount() {
      return commaDecimal(this.inviteeCount);
    },
    _maxInviteeCount() {
      return commaDecimal(this.maxInviteeCount);
    },
    inviteeProgressWidth() {
      return (this.inviteeCount / this.maxInviteeCount) * 100;
    },
    _rewardAmount() {
      return commaDecimal(this.reward);
    },
    _maxRewardAmount() {
      return commaDecimal(this.maxRewardAmount);
    },
    rewardProgressWidth() {
      return (this.reward / this.maxRewardAmount) * 100;
    },
  },
  data: () => ({
    maxInviteeCount: 100,
    maxRewardAmount: 100000,
  }),
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[invitation-event-info-panel] { .flex; .justify-center; .w(100%); .h(100%); .bgc(#e5eaff);
  .inner {.w(798); .p(40, 20);}
  .title-wrap { .flex; flex-direction: column; .items-center; .mb(32);
    > h2 {.fs(24); .mb(8); font-family: KotraBold; .c(#191919); .nowrap;
      > em {.c(#2f50f8); }
    }
    > p { .fs(13); .c(#191919);}
  }

  .panel-wrap { .flex; flex-direction: column; .justify-center; .rel; .w(100%); .h(161); .bgc(#fff); .br(30);
    .cover { .abs; .flex; flex-direction: column; .justify-center; .items-center; .w(100%); .h(100%); background-color: rgba(255, 255, 255, 0.85); .br(30); .z(1);
      > img { .w(50); .h(52.86); .mb(22);}
      > p {.fs(16); .bold; .c(#000);}
    }
    .progress { .p(20);
      &.reward-amount { .pt(0); }
      .progress-top { .flex;
        > img {.w(20); .h(20); .mr(8);}
        > h4 {.fs(14); .bold; .o(0.8); .c(#000); }
        > span {.fs(14); .o(0.8); .c(#d8d8d8); .ml(auto);
          span > em {.c(#054eeb);}
        }
      }
      .progress-bottom { .mt(18);
        .progress-bar-holder { .w(100%); .h(16); .bgc(#d8d8d8); .br(50px); overflow: hidden;
          .progress-bar {.w(0); .h(100%); background: (linear-gradient(90deg, #ff6d3a 3.59%, #ff8dc1 38.24%, #b338d3 68.87%, #2981f4 100%)); }
        }
      }
    }
  }

  @media (@tp-up) {
    .inner { .p(100, 0); }
    .title-wrap {.mb(32);
      > h2 {.fs(36); .mb(8);
        > em {.c(#2f50f8); }
      }
      > p { .fs(24);}
    }

    .panel-wrap { .h(288);
      .cover {
        > img { .w(70); .h(74); .mb(22);}
        > p {.fs(24);}
      }
      .progress { .p(20);
        .progress-top { .flex;
          > img {.w(36); .h(36); .mr(12);}
          > h4 {.fs(24); }
          > span {.fs(24); .o(0.8); .c(#d8d8d8); .ml(auto);
            span > em {.c(#054eeb);}
          }
        }
        .progress-bottom { .mt(18);
          .progress-bar-holder { .h(24);
            .progress-bar {.w(0); .h(100%); background: (linear-gradient(90deg, #ff6d3a 3.59%, #ff8dc1 38.24%, #b338d3 68.87%, #2981f4 100%)); }
          }
        }
      }
    }
  }
}

</style>
