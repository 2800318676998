export default {
  methods: {
    shareFb() {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`, 'share', 'width=800,height=800');
    },
    shareTw() {
      window.open(`https://twitter.com/share?url=${encodeURIComponent(window.location.href)}`, 'share', 'width=800,height=800');
    },
    shareIn() {
      window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}`, 'share', 'width=800,height=800');
    },
    /**
     * @param {string} url
     * @param {string} title
     * @param {string} description
     * @param {string} image
     */
    shareKakao({ url, title, description, imageUrl }) {
      url = url || window.location.href;
      const link = {
        mobileWebUrl: url,
        webUrl: url,
      };
      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: { title, description, imageUrl, link },
        buttons: [
          {
            title: '웹으로 보기',
            link,
          },
          {
            title: '앱으로 보기',
            link,
          },
        ],
      });
    },
  },
};
