<template>
  <div coach-event-invitation>
    <button class="btn-prev" @click="prevBtnHandler">
      <img src="/img/icon/icon-arrow-down-white.svg">
    </button>
    <section class="banner">
      <InvitationEventBanner />
    </section>
    <section class="controller-wrap">
      <ColorButton class="invite-btn" color="black" @click="invite">{{ $t('coaching.BT_LABEL_SEND_REFERRAL') }}</ColorButton>
    </section>
    <section class="info">
      <InvitationEventInfoPanel v-bind="statusResult" />
    </section>
    <section class="benefit">
      <InvitationEventMiddle />
    </section>
    <section class="guide">
      <InvitationEventGuide />
    </section>
    <section class="bottom-banner">
      <InvitationEventBottomBanner @click="goReceive" />
    </section>
    <section class="bottom-detail">
      <InvitationEventBottomDetail />
    </section>
  </div>
</template>

<script>
import ColorButton from '@shared/components/common/ColorButton.vue';
import InvitationEventBanner from '@/views/components/coaching/event/InvitationEventBanner.vue';
import InvitationEventInfoPanel from '@/views/components/coaching/event/InvitationEventInfoPanel.vue';
import InvitationEventMiddle from '@/views/components/coaching/event/InvitationEventMiddle.vue';
import InvitationEventGuide from '@/views/components/coaching/event/InvitationEventGuide.vue';
import InvitationEventBottomBanner from '@/views/components/coaching/event/InvitationEventBottomBanner.vue';
import InvitationEventBottomDetail from '@/views/components/coaching/event/InvitationEventBottomDetail.vue';
import InvitationEventModal from '@/views/components/coaching/event/InvitationEventModal.vue';

export default {
  name: 'CoachEventInvitation',
  components: { InvitationEventBottomDetail, InvitationEventBottomBanner, InvitationEventGuide, InvitationEventMiddle, InvitationEventInfoPanel, InvitationEventBanner, ColorButton },
  data: () => ({
    statusResult: {},
    showCover: true,
  }),
  metaInfo() {
    return {
      title: this.$t('meta.event.invitation.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.event.invitation.title') },
        { vmid: 'description', content: this.$t('meta.event.invitation.description') },
        { vmid: 'url', content: 'https://co.lvup.gg/event/invitation' },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: 'website' },
        { vmid: 'image', content: `${process.env.VUE_APP_HOST}/img/meta/LVUP-event.png` },
        { vmid: 'keywords', content: this.$t('meta.event.invitation.keywords') },
      ],
    };
  },
  computed: {
    userInfo() {
      return this.$store?.state?.auth?.myInfo;
    },
    isCoach() {
      return this.myInfo?.isCoach || false;
    },
  },
  methods: {
    async getFriendsInvitationStatus() {
      try {
        const statusResult = await this.$services.coaching.getFriendsInvitationStatus();
        statusResult.showCover = false;
        this.statusResult = statusResult;
      } catch (e) {
        console.log(e);
      }
    },
    async invite() {
      if (!this.userInfo) return this.$services.auth.oAuthLogin(true);
      if (this.isCoach) return this.$toast('coaching.FRIENDS_INVITATION_VALIDATION_USER_TYPE', { type: 'fail' });
      try {
        const { code } = await this.$services?.coaching?.getInvitationCode() ?? {};
        await this.$modal(InvitationEventModal, { code });
        this.$toast('coaching.FRIENDS_INVITATION_SUCCESS', { type: 'success' });
      } catch (err) {
        const errCode = err?.[1] || 'ERROR_DEFAULT';
        this.$toast(`coaching.FRIENDS_INVITATION_${errCode}`, { type: 'fail' });
      }
    },
    prevBtnHandler() {
      this.$router.push({ name: 'CoachHome' });
    },
    goReceive() {
      this.$router.push({ name: 'CoachEventInvitationReceive' });
    },
  },
  async mounted() {
    await this.getFriendsInvitationStatus();
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-event-invitation] { .rel;
  .btn-prev { .abs; display: unset; .t(20); .l(20); .flex; .w(40); .h(40); transform: rotate(90deg); .bgc(rgba(0, 0, 0, 0.6)); .br(12);
    &:hover {
      > img { .o(0.4);}
    }
    > img { .m(auto); .o(0.8);}
  }

  .controller-wrap { .flex; .items-center; .justify-center; .h(138); .bgc(#fff);
    .invite-btn {.w(335); .h(58); .fs(18); .bold; .bgc(#191919);}
  }

  @media (@tp-up) {
    .btn-prev {.hide;}
    .controller-wrap { .h(194);
      .invite-btn {.w(360); .h(74); .fs(24);}
    }
  }
}

</style>
